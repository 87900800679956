<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h5>Actualización de órdenes</h5>
        <div class="p-fluid formgrid grid">

          <div class="field col-6">
            <label for="username">Nuevo estatus</label>
            <Dropdown v-model="selectedStatus" :options="statusChoices" optionLabel="name" placeholder="Estatus destino"/>
          </div>
          <div class="field col-6">
            <label for="username">Escanea para actualizar</label>
            <div class="p-inputgroup">
            <span class="p-inputgroup-addon">
                        TRK
                    </span>
              <InputText id="username" type="text" v-model="trackID" @keyup.enter="updateOrder" :readonly="readOnly"/>
            </div>
          </div>
        </div>
        <div class="p-fluid formgrid grid" v-if="selectedStatus && selectedStatus.code === 'Entrega pendiente'">
          <div class="field col-6">
            <label for="guidesDate">Fecha de entrega</label>
            <Calendar id="guidesDate" v-model="deliveryDate" :showTime="false"
                      hourFormat="12" dateFormat="dd/mm/yy" :showSeconds="false" :minDate="new Date()"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Toast />
</template>

<script>
import { auth, pickup, sendNewStateNotificationByUID } from "../logic/firebase";

let selectedStatus = null;
let trackID = null;
let statusChoices = [
  {name: 'Recibida en sucursal (scan de llegada)', code: 'Recibida en sucursal'},
  {name: 'Lista para recoger (órdenes SUC)', code: 'Lista para recoger'},
  {name: 'Asignar entrega (órdenes DOM)', code: 'Asignar entrega'},
  {name: 'Entrega pendiente (forzar fecha de entrega orden DOM)', code: 'Entrega pendiente'},
  {name: 'Orden entregada (entrega órdenes SUC)', code: 'Orden entregada'}
]
let readOnly = false;
let deliveryDate;

export default {
  data() {
    return {
      selectedStatus: selectedStatus,
      statusChoices: statusChoices,
      trackID: trackID,
      readOnly: readOnly,
      deliveryDate: deliveryDate
    }
  },


  methods: {
    async updateOrder(){
      let dateOK;
      (this.selectedStatus && (this.selectedStatus.code === 'Entrega pendiente' && this.deliveryDate)
          || (this.selectedStatus.code !== 'Entrega pendiente')) ? dateOK = true : dateOK = false;
      if(this.selectedStatus && dateOK){
        this.readOnly = true;
        pickup.where('trkID', '==', "TRK" + this.trackID).limit(1).get().then(async (querySnapshot) => {
          if(querySnapshot.size === 1){
            if(querySnapshot.docs[0].data().state !== this.selectedStatus.code){
              let selectedDateNew;
              if(this.selectedStatus.code === 'Entrega pendiente'){
                //Update date too
                const year = this.deliveryDate.getFullYear()
                const month = ("0" + (this.deliveryDate.getMonth() + 1)).slice(-2)
                const day = ("0" + this.deliveryDate.getDate()).slice(-2)
                const dateString = year + '-' + month + '-' + day;
                selectedDateNew = dateString;
              }else{
                selectedDateNew = querySnapshot.docs[0].data().selectedDate;
              }
              await pickup.doc(querySnapshot.docs[0].id).update({
                state: this.selectedStatus.code,
                selectedDate: selectedDateNew
              }).then(async () => {
                let orderData = querySnapshot.docs[0].data();
                orderData.selectedDate = selectedDateNew;
                console.log("Success updating TRK " + this.trackID)
                this.displayToast('success', "Scan correcto",
                    "La orden se actualizó correctamente.", 2000)
                await sendNewStateNotificationByUID({
                  newState: this.selectedStatus.code,
                  uid: querySnapshot.docs[0].data().uid,
                  order: orderData
                }).then(async (result) => {
                  console.log("Sent notification to user");
                })
              }).catch((error) => {
                console.log("Error updating TRK", error)
                console.log({
                  newState: this.selectedStatus.code,
                  uid: querySnapshot.docs[0].data().uid,
                  order: querySnapshot.docs[0].data()
                })
              })
            }else{
              this.displayToast('error', "Estatus inválido",
                  "La orden ya se encuentra en el estatus seleccionado.", 3000)
            }
          }
        })
      }else{
        console.log("Invalid status or status not selected / No date selected")
        if(!this.selectedStatus){
          this.displayToast('error', "Selecciona un estatus",
              "Debes seleccionar un estatus antes de escanear.", 3000)
        }
        if(!this.deliveryDate && this.selectedStatus && this.selectedStatus.code === 'Entrega pendiente'){
          this.displayToast('error', "Selecciona fecha de entrega",
              "Debes seleccionar la fecha de entrega para el estatus seleccionado.", 3000)
        }
      }
      this.trackID = '';
      this.readOnly = false;
    },//updateOrder

    displayToast(severity, summary, details, life){
      /*
       * SEVERITY: success, info, warn, error
       */
      this.$toast.add({severity: severity, summary: summary, detail: details, life: life});
    },//displayToast
  },


  mounted() {

  },
}
</script>

<style scoped>
::v-deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}
</style>